// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: path(ROOTS_DASHBOARD, '/home'),
  about: path(ROOTS_DASHBOARD, '/about'),
  knownapis: path(ROOTS_DASHBOARD, '/knownapis'),
  knowntools: path(ROOTS_DASHBOARD, '/knowntools'),
  toolkit: path(ROOTS_DASHBOARD, '/toolkit'),
  api: path(ROOTS_DASHBOARD, '/api'),
  viewer: path(ROOTS_DASHBOARD, '/viewer'),
  // user: {
  //   root: path(ROOTS_DASHBOARD, '/user'),
  //   four: path(ROOTS_DASHBOARD, '/user/four'),
  //   five: path(ROOTS_DASHBOARD, '/user/five'),
  //   six: path(ROOTS_DASHBOARD, '/user/six'),
  // },
};
