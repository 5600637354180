// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  chat: icon('ic_chat'),
  menu: icon('ic_menu_item'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Home', path: PATH_DASHBOARD.home, icon: ICONS.dashboard },
      { title: 'Viewer', path: PATH_DASHBOARD.viewer, icon: ICONS.analytics },
      { title: 'API', path: PATH_DASHBOARD.api, icon: ICONS.menu },
      { title: 'Known APIs', path: PATH_DASHBOARD.knownapis, icon: ICONS.menu },
      // { title: 'Known Tools', path: PATH_DASHBOARD.knowntools, icon: ICONS.menu },
      { title: 'About', path: PATH_DASHBOARD.about, icon: ICONS.chat },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: PATH_DASHBOARD.user.four },
  //         { title: 'Five', path: PATH_DASHBOARD.user.five },
  //         { title: 'Six', path: PATH_DASHBOARD.user.six },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
