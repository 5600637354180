import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));
export const PageHome = Loadable(lazy(() => import('../pages/PageHome')));
export const PageViewer = Loadable(lazy(() => import('../pages/PageViewer')));
export const PageApi = Loadable(lazy(() => import('../pages/PageApi')));
export const PageKnownApis = Loadable(lazy(() => import('../pages/PageKnownApis')));
export const PageToolkit = Loadable(lazy(() => import('../pages/PageToolkit')));
export const PageAbout = Loadable(lazy(() => import('../pages/PageAbout')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
